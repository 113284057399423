import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Helmet } from "react-helmet";
import dmf from "../../Services/common";

const ContactUs = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.first_name) {
      errors["first_name"] = "First Name can not be empty";
      formIsValid = false;
    }
    if (!fields.last_name) {
      errors["last_name"] = "Last Name can not be empty";
      formIsValid = false;
    }
    if (!fields.phone) {
      errors["phone"] = "Phone can not be empty";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (!fields.i_am_an) {
      errors["i_am_an"] = "I am (n) can not be empty";
      formIsValid = false;
    }
    if (!fields.city) {
      errors["city"] = "City can not be empty";
      formIsValid = false;
    }
    if (!fields.state) {
      errors["state"] = "State can not be empty";
      formIsValid = false;
    }
    if (!fields.address) {
      errors["address"] = "Address can not be empty";
      formIsValid = false;
    }
    if (!fields.initial_funding_amount) {
      errors["initial_funding_amount"] = "Amount can not be empty";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };
  const handlePhone = (phone, field_name) => {
    fields[field_name] = "+" + phone;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      setIsloader(true);
      dmf
        .add(params)
        .then((res) => {
          setIsloader(false);
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            setErrors({});
            setIsloader(false);
          } else {
            let errors = {};
            for (let key in res.data.errors) {
              errors[key] = res.data.errors[key];
            }
            setErrors(errors);
            setIsloader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setIsloader(false);
        });
    }
  };
  return (
    <>
      <Helmet>
        <title>ContactUs : Credit-Flow</title>
      </Helmet>
      <section>
        <Container className="px-5">
          <Row className="justify-content-center">
            <Col md={6} className="mb-4">
             
            </Col>
            <Col md={6}>
              <Card className="login">
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="first_name">First Name</Label>
                          <Input
                            id="first_name"
                            name="first_name"
                            placeholder=""
                            type="first_name"
                            value={
                              fields["first_name"] ? fields["first_name"] : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "first_name")
                            }
                            invalid={errors["first_name"] ? true : false}
                          />
                          <FormFeedback>{errors["first_name"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="last_name">Last Name</Label>
                          <Input
                            id="last_name"
                            name="last_name"
                            placeholder=""
                            type="last_name"
                            value={
                              fields["last_name"] ? fields["last_name"] : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "last_name")
                            }
                            invalid={errors["last_name"] ? true : false}
                          />
                          <FormFeedback>{errors["last_name"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            placeholder=""
                            type="text"
                            value={fields["email"] ? fields["email"] : ""}
                            onChange={(event) => handleChange(event, "email")}
                            invalid={errors["email"] ? true : false}
                          />
                          <FormFeedback>{errors["email"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="phone">Phone</Label>
                          <PhoneInput
                           onlyCountries={["us","cu","cw","kz","in"]}
                            value={fields["phone"] ? fields["phone"] : ""}
                            onChange={(phone) => handlePhone(phone, "phone")}
                            country="us"
                          />
                          {errors["phone"] && (
                            <small className="fa-1x text-danger">
                              {errors["phone"]}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="address">Address</Label>
                          <Input
                            id="address"
                            name="address"
                            placeholder=""
                            type="address"
                            value={fields["address"] ? fields["address"] : ""}
                            onChange={(event) => handleChange(event, "address")}
                            invalid={errors["address"] ? true : false}
                          />
                          <FormFeedback>{errors["address"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="city">City</Label>
                          <Input
                            id="city"
                            name="city"
                            placeholder=""
                            type="text"
                            value={fields["city"] ? fields["city"] : ""}
                            onChange={(event) => handleChange(event, "city")}
                            invalid={errors["city"] ? true : false}
                          />
                          <FormFeedback>{errors["city"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="state">State</Label>
                          <Input
                            id="state"
                            name="state"
                            placeholder=""
                            type="state"
                            value={fields["state"] ? fields["state"] : ""}
                            onChange={(event) => handleChange(event, "state")}
                            invalid={errors["state"] ? true : false}
                          />
                          <FormFeedback>{errors["state"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12} className="text-right">
                        <Button
                          color="success"
                          size="lg"
                          type="submit"
                          disabled={isloader}
                        >
                          {isloader ? (
                            <Spinner
                              style={{
                                width: "1rem",
                                height: "1rem",
                                marginLeft: "5px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="svg-border-rounded text-white banner-arc">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="currentColor"
        >
          <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
        </svg>
      </div>

      
    </>
  );
};

export default ContactUs;
