import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import common from "../../Services/common";

const LoanDetailsBody = (props) => {
  const loan = props.loan;
  const additonalRemark = props.loan.additional_remark ? props.loan.additional_remark : "N/A"
  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label className="mr-2">
              <strong style={{ whiteSpace: "pre" }}>City: </strong>
            </Label>
            {loan.city.name}
          </FormGroup>
          <FormGroup>
            <Label className="mr-2">
              <strong style={{ whiteSpace: "pre" }}>Name: </strong>{" "}
            </Label>
            {loan.customer_name}
          </FormGroup>
          <FormGroup>
            <Label className="mr-2">
              <strong style={{ whiteSpace: "pre" }}>Sales Manager Name: </strong>{" "}
            </Label>
            {loan.sm_name}
          </FormGroup>
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Phone: </strong>{" "}
            </Label>
            {loan.customer_phone}
          </FormGroup>
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Company Name: </strong>{" "}
            </Label>
            {loan.company}
          </FormGroup>
        </Col>
        
        <Col md={6}>
        <FormGroup>
            <Label className="mr-2">
              <strong style={{ whiteSpace: "pre" }}>Bank Name: </strong>{" "}
            </Label>
            {loan.bank_name}
          </FormGroup>
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Amount: </strong>
            </Label>
            {/* {loan.amount ? `₹${loan.amount}` : "N/A"} */}
            {loan.amount ? common.numberFormat(loan.amount) : "N/A"}
          </FormGroup>
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Los Number: </strong>{" "}
            </Label>
            {loan.los_number}
          </FormGroup>
          
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Loan Type: </strong>{" "}
            </Label>
            {loan.loan_type}
          </FormGroup>
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Loan Disbursed: </strong>
            </Label>
            {loan.amount_disbursed ? common.numberFormat(loan.amount_disbursed) : "N/A"}
          </FormGroup>
        </Col>

        <Col md={12}>
            <FormGroup>
              <Label className="mr-2">
                <strong style={{ whiteSpace: "pre" }}>Address: </strong> <br />
              </Label>
              {loan.customer_address}
            </FormGroup>
          </Col>
        <Col md={12}>
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Remark: </strong>
            </Label>
            {loan.remark}
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Added By: </strong>
            </Label>
            {loan.user.name}
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup>
            <Label>
              <strong style={{ whiteSpace: "pre" }}>Additional Remark: </strong>
            </Label>
            {additonalRemark} 
          </FormGroup>  
        </Col>
      </Row>
    </>
  );
};

export default LoanDetailsBody;
