import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import user from "../../Services/user";

function Login(props) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoader(true);
      let params = fields;
      user
        .login(params)
        .then((data) => {
          if (data.data.success) {
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("isAdmin", data.data.user.is_admin);
            localStorage.setItem("email", data.data.user.email);
            localStorage.setItem("user", JSON.stringify(data.data.user));
            localStorage.setItem("userId", JSON.stringify(data.data.user.id));
            localStorage.setItem("name", JSON.stringify(data.data.user.name));
            let payload = {
              token: data.data.token,
              userId: data.data.user.id,
              email: data.data.user.email,
              name: data.data.user.name,
              isAdmin: data.data.user.is_admin,
            };
            dispatch({ type: "login", ...payload });
            setLoader(false);
            navigate("/admin/dashboard");
          } else if (data.data.error) {
            if (data.data.message) {
              let errors = {};
              errors['password'] = data.data.message;
              setErrors(errors);
              setLoader(false);
            }
          } else {
            let errors = {};
            for (let key in data.data.errors) {
              errors[key] = data.data.errors[key];
            }
            setErrors(errors);
            setLoader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setLoader(false);
        });
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    if (!fields.password) {
      errors["password"] = "Password can not be empty";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  /* if (localStorage.getItem("token") && parseInt(localStorage.getItem("isAdmin")) === 1) {
    return   window.location.href = "/admin/dashboard";
  } else if(localStorage.getItem("token")){
    return <Navigate to="/dashboard" />;
  } */
  return (
    <>
      <Helmet>
        <title>Login : Credit Flow</title>
      </Helmet>
      <section>
        <Container className="p-5">
          <Row className="justify-content-center">
            <Col md={6} className="mb-4">
              <h1>Build your next project faster with SB UI Kit Pro</h1>
              <p>
                Welcome to SB UI Kit Pro, a toolkit for building beautiful web
                interfaces, created by the development team at Start Bootstrap
              </p>
            </Col>

            <Col md={6}>
              <Card className="login">
                <CardHeader>
                  <h6 className="text-primary mt-2">Sign In</h6>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="email">Email Address</Label>
                      <Input
                        id="email"
                        name="email"
                        placeholder=""
                        type="text"
                        value={fields["email"] ? fields["email"] : ""}
                        onChange={(event) => handleChange(event, "email")}
                        invalid={errors["email"] ? true : false}
                      />
                      <FormFeedback>{errors["email"]}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        id="password"
                        name="password"
                        placeholder=""
                        type="password"
                        value={fields["password"] ? fields["password"] : ""}
                        onChange={(event) =>
                          handleChange(event, "password")
                        }
                        invalid={errors["password"] ? true : false}
                      />
                      <FormFeedback>{errors["password"]}</FormFeedback>
                    </FormGroup>
                    <Row>
                      <Col md={8} className="d-grid gap-2 mx-auto text-center">
                      <Button
                          color="success"
                          size="lg"
                          type="submit"
                          disabled={loader}
                        >
                          {loader && (
                            <Spinner
                              style={{
                                width: 20,
                                height: 20,
                              }}
                              size="sm"
                              className="mx-1"
                            />
                          )}
                          Sign In
                        </Button>
                        <Link to="/forgot-password">Forgot Password? </Link>
                      </Col>
                    </Row>
                  </Form>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="svg-border-rounded text-white banner-arc">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="currentColor"
        >
          <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
        </svg>
      </div>
    </>
  );
}

export default Login;
