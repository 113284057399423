import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import "react-phone-input-2/lib/style.css";
import user from "../../Services/user";
import LoadingOverlay from "react-loading-overlay";

const AddEditUser = (props) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);
  const [loader, setLoader] = useState(false);

  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.name) {
      errors["name"] = "Name can not be empty";
      formIsValid = false;
    }
    if (!fields.email) {
      errors["email"] = "Email can not be empty";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (e, field) => {
    setFields({
      ...fields,
      [field]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = fields;
      console.log(params);
      setIsloader(true);
      user.addUser(params).then((res) => {
        console.log(res);
        setIsloader(false);
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsloader(true);
          props.handleClose();
          props.getUsers();
        } else {
          let errors = {};
          for (let key in res.data.errors) {
            errors[key] = res.data.errors[key];
          }
          setErrors(errors);
          setIsloader(false);
        }
      });
    }
  };

  const getSingleUser = (id) => {
    setLoader(true);
    user
      .getOne(id)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setFields(res.data.user);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (props.userId) {
      getSingleUser(props.userId);
    }
  }, [props.userId]);

  return (
    <>
      <Modal isOpen={props.open}>
        <ModalHeader toggle={props.handleClose}>{props.userId ? "Update " : "Add "}User</ModalHeader>
        <ModalBody className="pl-4 pr-4">
          <Form onSubmit={handleSubmit}>
            <LoadingOverlay
              active={loader}
              spinner={<Spinner color="dark" />}
              fadeSpeed={100}
              classNamePrefix="mitiz"
            >
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Name"
                      type="name"
                      value={fields["name"] ? fields["name"] : ""}
                      onChange={(event) => handleChange(event, "name")}
                      invalid={errors["name"] ? true : false}
                    />
                    <FormFeedback>{errors["name"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={fields["email"] ? fields["email"] : ""}
                      onChange={(event) => handleChange(event, "email")}
                      invalid={errors["email"] ? true : false}
                    />
                    <FormFeedback>{errors["email"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      id="password"
                      name="password"
                      placeholder="Password"
                      type="password"
                      value={fields["password"] ? fields["password"] : ""}
                      onChange={(event) => handleChange(event, "password")}
                      invalid={errors["password"] ? true : false}
                      error={errors["password"] ? true : false}
                    />
                    <FormFeedback>{errors["password"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="confirm_password">Confirm Password</Label>
                    <Input
                      id="password_confirmation"
                      name="password_confirmation"
                      placeholder="Confirm Password"
                      type="password"
                      value={
                        fields["password_confirmation"]
                          ? fields["password_confirmation"]
                          : ""
                      }
                      onChange={(event) =>
                        handleChange(event, "password_confirmation")
                      }
                      invalid={errors["password_confirmation"] ? true : false}
                    />
                    <FormFeedback>
                      {errors["password_confirmation"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Row>
                  <Label>Status</Label>
                  <Col md={6}>
                    <FormGroup check inline>
                      <Label>
                        <Input
                          type="radio"
                          name="status"
                          onChange={(event) => handleChange(event, "status")}
                          value={1}
                          checked={fields.status == 1}
                        />
                        Active
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label>
                        <Input
                          type="radio"
                          name="status"
                          onChange={(event) => handleChange(event, "status")}
                          value={0}
                          checked={fields.status == 0}
                        />
                        Inactive
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Col md={12}>
                  <FormGroup>
                    <Input
                      className="me-2"
                      type="checkbox"
                      color="primary"
                      name="is_admin"
                      id="is_admin"
                      onChange={(event) => handleChange(event, "is_admin")}
                      checked={fields?.is_admin}
                    />
                    <Label for="is_admin">Is Admin</Label>
                    <FormFeedback>{errors["is_admin"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <ModalFooter>
                  <Button
                    type="button"
                    size="md"
                    outline
                    className="me-1"
                    color="secondary"
                    onClick={props.handleClose}
                    disabled={isloader}
                  >
                    Close
                  </Button>
                  <Button
                    color="danger"
                    size="md"
                    type="submit"
                    disabled={isloader}
                  >
                    {isloader ? (
                      <Spinner
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginLeft: "5px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {props.userId ? "Update" : "Save"}
                  </Button>
                </ModalFooter>
              </Row>
            </LoadingOverlay>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddEditUser;
