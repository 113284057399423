import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import user from "../../Services/user";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Index = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");
  const email = query.get("email");

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let params = fields;
    params["token"] = token;
    params["email"] = email;
    setLoader(true);
    user.resetpassword(params).then((res) => {
      setLoader(true);
      if (res.data.success) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
        navigate("/login");
        setFields({});
      } else {
        let errors = {};
        for (let key in res.data.errors) {
          errors[key] = res.data.errors[key];
        }
        setErrors(errors);
        setLoader(false);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Reset Password : credit-flow</title>
      </Helmet>
      <section>
        <Container className="px-5">
          <Row className="justify-content-center">
            <Col md={6} className="mb-4">
              <h1>Build your next project faster with SB UI Kit Pro</h1>
              <p>
                Welcome to SB UI Kit Pro, a toolkit for building beautiful web
                interfaces, created by the development team at Start Bootstrap
              </p>
            </Col>

            <Col md={6}>
              <Card className="login">
                <CardHeader>
                  <h6 className="text-primary mt-2">Reset Password</h6>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="password">Password</Label>
                          <Input
                            id="password"
                            name="password"
                            placeholder="Enter your password"
                            type="password"
                            value={fields["password"] ? fields["password"] : ""}
                            onChange={(event) =>
                              handleChange(event, "password")
                            }
                            invalid={errors["password"] ? true : false}
                          />
                          <FormFeedback>{errors["password"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="password_confirmation">
                            Confirm Password
                          </Label>
                          <Input
                            id="password_confirmation"
                            name="password_confirmation"
                            placeholder="Enter your confirm password"
                            type="password"
                            value={
                              fields["password_confirmation"]
                                ? fields["password_confirmation"]
                                : ""
                            }
                            onChange={(event) =>
                              handleChange(event, "password_confirmation")
                            }
                            invalid={
                              errors["password_confirmation"] ? true : false
                            }
                          />
                          <FormFeedback>
                            {errors["password_confirmation"]}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12} className="text-right">
                        <Button
                          color="success"
                          size="lg"
                          type="submit"
                          disabled={loader}
                        >
                          {loader ? (
                            <Spinner
                              style={{
                                width: "1rem",
                                height: "1rem",
                                marginLeft: "5px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="svg-border-rounded text-white banner-arc">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="currentColor"
        >
          <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
        </svg>
      </div>

      <Container className="px-5">
        <div style={{ height: 400 }}></div>
      </Container>
    </>
  );
};

export default Index;
