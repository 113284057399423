import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

function About(props) {

  return (
    <Container className="px-5">
      <div style={{height:400}}>
        About
      </div>
    </Container>
  );
}

export default About;
