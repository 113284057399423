import { createStore } from 'redux'

let apiUrl, baseUrl;
if (document.URL.includes("localhost")) {
  apiUrl = "http://192.168.29.242:8000/api";
  baseUrl = "http://192.168.29.242:8000";
}
else {
  apiUrl = "https://creditflow.co.in/public/api";
  baseUrl = "https://creditflow.co.in";
}

let initialState = {
  apiUrl: apiUrl,
  baseUrl: baseUrl,

};
if (localStorage.getItem("token")) {
  initialState = { ...initialState, token: localStorage.getItem("token") };
}
if (localStorage.getItem("userName")) {
  initialState = {
    ...initialState,
    userName: localStorage.getItem("userName")
  };
}
if (localStorage.getItem("userEmail")) {
  initialState = {
    ...initialState,
    userEmail: localStorage.getItem("userEmail")
  };
}
if (localStorage.getItem("userId")) {
  initialState = { ...initialState, userId: localStorage.getItem("userId") };
}
if (localStorage.getItem("userType")) {
  initialState = {
    ...initialState,
    userType: localStorage.getItem("userType")
  };
}
if (localStorage.getItem("isAdmin")) {
  initialState = {
    ...initialState,
    isAdmin: localStorage.getItem("isAdmin")
  };
}
if (localStorage.getItem("name")) {
  initialState = {
    ...initialState,
    name: localStorage.getItem("name")
  };
}
if (localStorage.getItem("email")) {
  initialState = {
    ...initialState,
    email: localStorage.getItem("email")
  };
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'login':
      return { ...state, ...rest }
    case 'logout':
      localStorage.clear();
      const initialState = {
        apiUrl: apiUrl,
        baseUrl: baseUrl
      };
      return (state = initialState);
    default:
      return state
  }
}

const store = createStore(changeState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
store.subscribe(() => {
  console.log(store);
});
export default store;