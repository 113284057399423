// import React from "react";
import Home from "../Frontend/Home";
import About from "../Frontend/About";
import Term from "../Frontend/Term";
import Support from "../Frontend/Support";
import Error from "../Frontend/Error";
import Login from "../Frontend/Login";
import ForgotPassword from "../Frontend/ForgotPassword"
import ChangePassword from "../Frontend/ChangePassword"
import ResetPassword from "../Frontend/ResetPassword"
import ContactUs from "../Frontend/ContactUs";
import Setting from "../Auth/Setting";

const FrontRoutes = [
  {
    path: "/", 
    name: "Home", 
    element: Home, 
    authentication: false 
  },
  {
    path: "/about-us", 
    name: "About", 
    element: About, 
    authentication: true 
  },
  {
    path: "/term", 
    name: "Term", 
    element: Term, 
    authentication: true 
  },
  {
    path: "/support", 
    name: "Support", 
    element: Support, 
    authentication: true 
  },
  {
    path: "/login", 
    name: "Login", 
    element: Login, 
    authentication: false 
  },
  {
    path: "/contact-us", 
    name: "Contact-us", 
    element: ContactUs, 
    authentication: false 
  },
  {
    path: "/forgot-password", 
    name: "Forgot Password", 
    element: ForgotPassword, 
    authentication: false 
  },
  {
    path: "/reset-password", 
    name: "Reset Password", 
    element: ResetPassword, 
    authentication: false 
  },
  {
    path: "/change-password", 
    name: "Change Password", 
    element: ChangePassword, 
    authentication: true 
  },
  {
    path: "/setting", 
    name: "Setting", 
    element: Setting, 
    authentication: true 
  },
  {
    path: "/error", 
    name: "Error", 
    element: Error, 
    authentication: true 
  },
];

export default FrontRoutes;