import React, { useEffect, useState } from "react";
import {
  Container, Row, Col, Button
} from 'reactstrap';
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Home(props) {

  return (
    <>
     <Helmet>
          <title>Home : Credit Flow</title>
        </Helmet> 
      <section>
        <Container className="p-5">
          <Row className="justify-content-center">
            <Col md={6} className="mb-4">
              <h2>Getting loan approvals has become easier!</h2>
              <p>We understand your need and help you out with mininum documentation, quick response and get your loan ready.</p>
              <p>Check your pre-quailifed loan offers.</p>
              <Link
                  to="/contact-us"
                  size="md"
                  className="btn btn-success shadow me-4"
                >
                  Get Started
                  <BsArrowRightShort />
                </Link>
            </Col>

            <Col md={6} className="text-center">
              <img src="/assets/images/windows.svg" style={{ width: '100%' }} />
            </Col>
          </Row>
        </Container>
      </section>
      <div className="svg-border-rounded text-white banner-arc">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
      </div>
    </>
  );
}

export default Home;
