import http from "./http";

const common = {
  getBuild: (param) => http.get("/app/version", { params: param }),
  add: (data) => http.post("/dmf-submit", data),
  list: (param) => http.get("/admin/list-dmf", { params: param }),
  Delete: (param) => http.delete(`/admin/delete-dmf/${param}`),
  getOne: (param) => http.get(`admin/get-dmf/${param}`),
  update: (data) => http.post(`admin/edit-dmf/${data.id}`, data),
  getFullName(data) {
    let name = "";

    if (data.first_name !== null) {
      name += data.first_name + ` `;
    }

    if (data.last_name !== null) {
      name += data.last_name + ` `;
    }

    return name;
  },
  numberFormat(amount) {
    let x=parseInt(amount).toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = '₹' + otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  },
};

export default common;
