import React, { Suspense, useState, useEffect } from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminRoutes from "./Routes/AdminRoutes";
import FrontRoutes from "./Routes/FrontRoutes";
import Home from "./Frontend/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error from "./Frontend/Error";
import common from "./Services/common";
const AdminLayout = React.lazy(() => import("../src/Layouts/AdminLayout"));
const FrontLayout = React.lazy(() => import("../src/Layouts/FrontLayout"));

function App() {
  let inactivityTime = function () {
    let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function refreshVersion() {
      const current_version = localStorage.getItem("APP_VERSION");
      common.getBuild().then((res) => {
          localStorage.setItem("APP_VERSION",res.data);
          if(current_version && res.data > current_version) {
            window.location.reload(true);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(refreshVersion, 180000);
    }
  };
  window.onload = function () {
    inactivityTime();
  };


  const token = useSelector((state) => state.token);
  const checkAuth = (route) => {
    if (route.authentication) {
      if (token === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense
        fallback={
          <div className="vh-100 d-flex align-items-center justify-content-center">
            Loading...
          </div>
        }
      >
        <Routes>
          <Route path="/admin" element={<AdminLayout />}>
            {AdminRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  checkAuth(route) ? <route.element /> : <Navigate to="/" />
                }
                // element={<route.element />}
              />
            ))}
          </Route>
          <Route path="/" element={<FrontLayout />}>
            {FrontRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  checkAuth(route) ? <route.element /> : <Navigate to="/" />
                }
              />
            ))}
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
