import http from "./http";

const loan = {
  loans: param => http.get("/loans/list", { params: param }),
  getCities: param => http.get("/loans/areas", { params: param }),
  getExcel: param => http.get("/loans/export-loans", { params: param, responseType: 'blob' }),
  loanDelete: (param) => http.delete(`/loans/delete/${param}`),
  getOneLoan: (param) => http.get(`/loans/show/${param}`),
  addUpdateLoan: (data) => {
    if(data.id){
      return http.post(`/loans/update/${data.id}`, data);
    }
    else{
      return http.post(`/loans/create`, data);
    }
  },
  recentLoan: param => http.get("/loans/recent-loans", { params: param }),
  todayLoan: param => http.get("/loans/today-loans", { params: param }),

}

export default loan;