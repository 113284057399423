import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  FormFeedback,
  Spinner,
  Container
} from "reactstrap";
import { Helmet } from "react-helmet";
import user from "../../Services/user";
const UpdatePassword = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let params = fields;
    setLoader(true);
    user
      .changepwd(params)
      .then((res) => {
        setLoader(true);
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
          setFields({});
        } else {
          let errors = {};
          for (let key in res.data.errors) {
            errors[key] = res.data.errors[key];
          }
          setErrors(errors);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setErrors(errors);
        setLoader(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Change Password : Credit Flow</title>
      </Helmet>

      <Container className="px-5">
      <div
        className="d-flex align-items-center justify-content-center mt-2"
        style={{ minHeight: 600 }}
      >
        <Card className="shadow p-4" style={{ width: "650px" }}>
          <h4>Change Password</h4>
          <hr />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="old_password">Old Password</Label>
                  <Input
                    id="old_password"
                    name="old_password"
                    placeholder="Enter old password"
                    type="password"
                    value={fields["old_password"] ? fields["old_password"] : ""}
                    onChange={(event) => handleChange(event, "old_password")}
                    invalid={errors["old_password"] ? true : false}
                  />
                  <FormFeedback>{errors["old_password"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="password">New Password</Label>
                  <Input
                    id="password"
                    name="password"
                    placeholder="Enter new password"
                    type="password"
                    value={fields["password"] ? fields["password"] : ""}
                    onChange={(event) => handleChange(event, "password")}
                    invalid={errors["password"] ? true : false}
                  />
                  <FormFeedback>{errors["password"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="password_confirmation">Confirm Password</Label>
                  <Input
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Enter confirm password"
                    type="password"
                    value={
                      fields["password_confirmation"]
                        ? fields["password_confirmation"]
                        : ""
                    }
                    onChange={(event) =>
                      handleChange(event, "password_confirmation")
                    }
                    invalid={errors["password_confirmation"] ? true : false}
                  />
                  <FormFeedback>{errors["password_confirmation"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Button
                  color="success"
                  size="lg"
                  type="submit"
                  disabled={loader}
                >
                  {loader ? (
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      </Container>
    </>
  );
};

export default UpdatePassword;
