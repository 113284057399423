import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {
  Button,
  Card,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import user from "../../Services/user";
const Setting = () => {
  const email = useSelector((state) => state.email);

  const [fields, setFields] = useState({ email });
  const [errors, setErrors] = useState({});
  const [isloader, setIsloader] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    let params = fields;
    setIsloader(true);
    user.updateEmail(params).then((res) => {
      setIsloader(true);
      if (res.data.success) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsloader(false);
      } else {
        let errors = {};
        for (let key in res.data.errors) {
          errors[key] = res.data.errors[key];
        }
        setErrors(errors);
        setIsloader(false);
      }
    });
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    let params = fields;
    setLoader(true);
    user
      .changepwd(params)
      .then((res) => {
        setLoader(true);
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
          setFields({});
          setErrors({});
        } else {
          let errors = {};
          for (let key in res.data.errors) {
            errors[key] = res.data.errors[key];
          }
          setErrors(errors);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setErrors(errors);
        setLoader(false);
      });
  };

  return (
    <div className="content-wrapper rounded-2 bg-white p-4">
      <Helmet>
        <title>Edit Profile : Credit Flow</title>
      </Helmet>

      <Container>
        <Row>
          <Col>
            <h4>Edit Profile</h4>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mt-4" stylCarde={{ minHeight: 300 }}>
              <Card className="shadow p-4">
                <Col>
                  <h4>Update Email</h4>
                </Col>

                <Col md={12} className="mt-2">
                  <form onSubmit={handleEmailSubmit}>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        placeholder=""
                        type="text"
                        value={fields["email"] ? fields["email"] : ""}
                        onChange={(event) => handleChange(event, "email")}
                        invalid={errors["email"] ? true : false}
                      />
                      <FormFeedback>{errors["email"]}</FormFeedback>
                    </FormGroup>
                    <Col className="d-flex justify-content-end">
                      <Button color="success" type="submit">
                        {isloader && (
                          <Spinner
                            style={{
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                        )}
                        Submit
                      </Button>
                    </Col>
                  </form>
                </Col>
              </Card>
            </div>
          </Col>

          <Col md={6}>
            <div className="mt-4" style={{ minHeight: 450 }}>
              <Card className="shadow p-4">
                <Col>
                  <h4>Change Password</h4>
                </Col>
                <Col md={12} className="mt-2">
                  <form onSubmit={handlePasswordSubmit}>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="old_password">
                          Enter Your Old Password
                        </Label>
                        <Input
                          id="old_password"
                          name="old_password"
                          placeholder=""
                          type="password"
                          value={
                            fields["old_password"] ? fields["old_password"] : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "old_password")
                          }
                          invalid={errors["old_password"] ? true : false}
                          error={errors["old_password"] ? true : false}
                        />
                        <FormFeedback>{errors["password"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="password">New Password</Label>
                        <Input
                          id="password"
                          name="password"
                          placeholder=""
                          type="password"
                          value={fields["password"] ? fields["password"] : ""}
                          onChange={(event) => handleChange(event, "password")}
                          invalid={errors["password"] ? true : false}
                          error={errors["password"] ? true : false}
                        />
                        <FormFeedback>{errors["password"]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="confirm_password">
                          Confirm New Password
                        </Label>
                        <Input
                          id="password_confirmation"
                          name="password_confirmation"
                          placeholder=""
                          type="password"
                          value={
                            fields["password_confirmation"]
                              ? fields["password_confirmation"]
                              : ""
                          }
                          onChange={(event) =>
                            handleChange(event, "password_confirmation")
                          }
                          invalid={
                            errors["password_confirmation"] ? true : false
                          }
                        />
                        <FormFeedback>
                          {errors["password_confirmation"]}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button color="success" type="submit">
                        {loader && (
                          <Spinner
                            style={{
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                        )}
                        Submit
                      </Button>
                    </Col>
                  </form>
                </Col>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Setting;
