import moment from "moment";
import React from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Badge } from "react-bootstrap";
import { MdModeEdit,MdDelete } from "react-icons/md";


const User = (props) => {
  const user = props.user;
  return (
    <tr key={user.id}>
      <td>{user.id}</td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>
        <Badge pill bg={user.is_admin == 1 ? "success" : "danger"}>
          {user.is_admin == "1" ? "Yes" : "No"}
        </Badge>
      </td>

      <td>
        {user.status == 0 ? (
          <Badge pill className="py-2" bg="danger">Inactive</Badge>
        ) : (
          <Badge pill className="py-2" bg="success">Active</Badge>
        )}
      </td>
      <td>{moment(user.created_at).format("D MMM,  YYYY")}</td>
      <td className="text-center">
        <UncontrolledDropdown className="me-2" direction="bottom">
          <DropdownToggle caret color="primary" className="btn-sm">
            Action
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => props.openEditModal(user.id)}>
             <span className="me-2 icon_badge"><MdModeEdit  /></span> Edit
            </DropdownItem>
            <DropdownItem
              onClick={() => props.deleteUser(user.id)}
              className="text-danger"
            >
             <span className="me-2 icon_badge"><MdDelete /></span> Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        {/* <button
          type="button"
          className="btn btn-info btn-sm text-white me-2"
          onClick={() => props.openEditModal(user.id)}
        >
          Edit
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm text-white"
          onClick={() => props.deleteUser(user.id)}
        >
          Delete
        </button> */}
      </td>
    </tr>
  );
};

export default User;
