import axios from "axios";
import store from "../Redux/store";
import { toast } from 'react-toastify';


let apiUrl;
if (window.location.href.indexOf("loscalhost") >= 0) {
  apiUrl = "http://creditflow.co.in/public/api";
  // apiUrl = "http://192.168.29.165/creditflow/api"
  //apiUrl = "https://creditflow-co-in.preview-domain.com/public/api"
}
else {
  if (window.location.href.indexOf("https") >= 0) {
    apiUrl = "https://creditflow.co.in/public/api";
    // apiUrl = "http://192.168.29.165/creditflow/api"
    //apiUrl = "https://creditflow-co-in.preview-domain.com/public/api"
  }
  else {
    //apiUrl = "http://creditflow.co.in/public/api";
    // apiUrl = "http://192.168.29.165/creditflow/api"
    apiUrl = "https://creditflow-co-in.preview-domain.com/public/api"
  }
}
apiUrl = "https://creditflow.co.in/public/api";

const http = axios.create({
  baseURL: apiUrl,
  //timeout: 1000,
  headers: { "Content-Type": "application/json" }
});
http.interceptors.request.use(
  config => {
    if (store.getState().token) {
      config.headers.common["Authorization"] = `Bearer  ${store.getState().token}`;
    }
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.clear();
      //window.location.href = "/";
    } else if (error.response.status === 404) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });

    } else if (error.response.status === 500) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (error.response.status === 403) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (error.response.status === 415) {
      toast.error(error.message + " - Unsupported media type. Please upload image file only.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      return Promise.resolve(error.response);

    }
    return Promise.reject(error);
  }
);

export default http;