import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import loan from "../../Services/loan";
import user from "../../Services/user";
import common from "../../Services/common";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FaHandHoldingUsd, FaUserCheck } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { GiPayMoney } from "react-icons/gi";

const Index = () => {
  const [todayloan, setTodayLoan] = useState([]);
  const [totalLoans, setTotalLoans] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [activeUser, setActiveUser] = useState([]);
  const [recentLoan, setrecentLoan] = useState([]);
  const [loader, setLoader] = useState(false);
  const isAdmin = useSelector((state) => state.isAdmin);

  const todayLoan = () => {
    loan
      .todayLoan()
      .then((res) => {
        if (res.data.success) {
          setTodayLoan(res.data.loans);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const totalLoan = () => {
    setLoader(true);
    loan
      .loans()
      .then((res) => {
        if (res.data.success) {
          setTotalLoans(res.data.loans.total);
        }
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };
  const recentLoans = () => {
    setLoader(true);
    loan
      .recentLoan()
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setrecentLoan(res.data.loans);
        }
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };
  const totalUser = () => {
    setLoader(true);
    user
      .user()
      .then((res) => {
        if (res.data.success) {
          setTotalUsers(res.data.users.total);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  const activeUsers = () => {
    setLoader(true);
    user
      .activeUser()
      .then((res) => {
        if (res.data.success) {
          setActiveUser(res.data.users);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    todayLoan();
    totalLoan();
    totalUser();
    activeUsers();
    recentLoans();
  }, []);

  return (
    <>
      <div className="content-wrapper rounded-2 bg-white p-4">
      <Helmet>
        <title>Dashboard: Credit-Flow</title>
      </Helmet>
      <Row>
        <Col xl={12} className="mb-3">
          <h3>Dashboard</h3>
        </Col>
      </Row>
      <Row>
      <Col sm={6} lg={3} className="mb-3">
          <Card className="bg-primary text-light">
            <CardBody>
              <Row>
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h3 className="mb-0 text-light">
                      {loader ? <Spinner /> : todayloan}
                    </h3>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon text-light">
                    <span style={{ fontSize: 50 }}>
                      <FaHandHoldingUsd />
                    </span>
                  </div>
                </div>
              </Row>
              <h5 className="font-weight-normal text-light">Today's Loans</h5>
            </CardBody>
          </Card>
        </Col>
        <Col sm={6} lg={3}>
          <Card className="bg-danger text-light">
            <CardBody>
              <Row>
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h3 className="mb-0 text-light">
                      {loader ? <Spinner /> : totalLoans}
                    </h3>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon text-light">
                    <span style={{ fontSize: 50 }}>
                      <GiTakeMyMoney />
                    </span>
                  </div>
                </div>
              </Row>
              <h5 className="font-weight-normal text-light">Total Loans</h5>
            </CardBody>
          </Card>
        </Col>
        {parseInt(isAdmin) === 1 && (
          <Col sm={6} lg={3} className="mb-3">
            <Card className="bg-warning text-light">
              <CardBody>
                <Row>
                  <div className="col-9">
                    <div className="d-flex align-items-center align-self-start">
                      <h3 className="mb-0 text-light">
                        {loader ? <Spinner /> : totalUsers}
                      </h3>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="icon text-light">
                      <span style={{ fontSize: 50 }}>
                        <HiOutlineUserGroup />
                      </span>
                    </div>
                  </div>
                </Row>
                <h5 className="font-weight-normal text-light">Total Users</h5>
              </CardBody>
            </Card>
          </Col>
        )}
                 <Col sm={6} lg={3}>
          <Card className="bg-info text-light">
            <CardBody>
              <Row>
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h3 className="mb-0 text-light">
                      {loader ? <Spinner /> : activeUser}
                    </h3>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon text-light">
                    <span style={{ fontSize: 50 }}>
                      <FaUserCheck />
                    </span>
                  </div>
                </div>
              </Row>
              <h5 className="font-weight-normal text-light">
                Total Active Users
              </h5>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    <div className="content-wrapper mt-4 rounded-2 bg-white p-4 mt-2">
    <Row>
        <Col xl={12}>
          <Row>
            <Col md={8} sm={12}>
              <h3>Recent Loans</h3>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col style={{ minHeight: 350 }}>
          {loader ? (
            <div className="text-center">
              <Spinner style={{ width: 60, height: 60 }} />
            </div>
          ) : (
            <>
              <div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Manager</th>
                      <th scope="col">City</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Added On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentLoan?.map((recent, i) => {
                      return (
                        <tr key={`recent-loan-${i}`}>
                          <td>{i + 1}.</td>
                          <td>{recent.customer_name}</td>
                          <td> {recent.sm_name} </td>
                          <td> {recent.city.name} </td>
                          <td>{recent.remark}</td>
                          <td>
                            {recent.amount
                              ? `${common.numberFormat(recent.amount)}`
                              : "N/A"}
                          </td>
                          <td>
                            {moment(recent.created_at).format("D MMM,  YYYY")}
                          </td>
                        </tr>
                      );
                    })}
                    {recentLoan.length <= 0 && (
                      <tr>
                        <td colSpan={7}>
                          <h6 className="text-gray text-center">
                            No records available
                          </h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Index;
