// import React from "react";
import Dashboard from "../Backend/Dashboard";
import Users from "../Backend/Users";
import Loans from "../Backend/Loans"
import Setting from "../Backend/Setting"
import Error from "../Frontend/Error";

const AdminRoutes = [
  {
    path: "dashboard", 
    name: "Dashboard", 
    element: Dashboard, 
    authentication: true 
  },
  {
    path: "users", 
    name: "Users", 
    element: Users, 
    authentication: true 
  },
  {
    path: "loans", 
    name: "loans", 
    element: Loans, 
    authentication: true 
  },
  {
    path: "settings", 
    name: "settings", 
    element: Setting, 
    authentication: true 
  },
  
  {
    path: "error", 
    name: "Error", 
    element: Error, 
    authentication: true 
  },
];

export default AdminRoutes;
