import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import {
  Col,
  Row,
  Table,
  Spinner,
  Button,
} from "reactstrap";
import Search from "../Search";
import User from "./User";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import user from "../../Services/user";
import { BsChevronDown, BsChevronExpand, BsChevronUp } from "react-icons/bs";
import AddEditUser from "./AddEditUser";

const Index = (props) => {
  const [users, setUsers] = useState({});
  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sort, setSort] = useState({});
  const [searchParams, setSearchParams] = useState({})
  const [searchOpen, setsearchOpen] = useState(false);
  const [perPageData, setPerPageData] = useState(0)
  const [searchFields, setSearchFields] = useState([
    { label: "Name", name: "name", type: "text", col: 4 },
    { label: "Email", name: "email", type: "text", col: 4 },
  ]);

  const openEditModal = (user_id) => {
    setOpen(true);
    setUserId(user_id);
  };

  const toggleSearch = (e) => {
    setsearchOpen(!searchOpen);
  };

  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction,
    });
  };

  const getSearchFields = (fields = {}) => {
    if (searchParams) {
      setPage(1);
      getUsers(fields);
    }
    setTotalPages(totalPages / perPageData);
  };

  const getUsers = (fields = {}) => {
    setLoader(true);
    let params = { ...fields, ...searchParams, ...sort, page: page }
    user
      .user(params)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setUsers(res.data.users.data);
          if(!searchParams){
            setPage(res.data.users.current_page);
          }
          setTotalPages(res.data.users.total);  
          setPerPageData(res.data.users.per_page)
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const deleteUser = (id) => {
    if (window.confirm("Are you sure to delete this user?")) {
      user.userDelete(id).then((res) => {
        if (res.data.success) {
          const newUser = users.filter((item) => item.id !== id);
          setUsers(newUser);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  useEffect(() => {
      getUsers(page, sort);
  }, [page, sort]);

  const resetPage = () => {
    setPage(1);
  }
  return (
    <div>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <Row>
        <Col xl={12}>
          <Row>
            <Col md={8} sm={12}>
              <h3 className="mt-2 text_title">Users</h3>
            </Col>
            <Col md={4} sm={12} className="text-end">
              <Button
                color="warning"
                type="button"
                onClick={toggleSearch}
                className="mx-2"
              >
                Search
              </Button>
              <Button
                color="success"
                type="button"
                onClick={() => setOpen(true)}
              >
                Add New User
              </Button>
            </Col>
          </Row>
          
          <Search
            isOpen={searchOpen}
            searchFields={searchFields}
            heading="Search User"
            searchRecord={getUsers}
            searchParamsfields={setSearchParams}
            resetPage={resetPage}
            getFieldsfromSearch={getSearchFields}
          />
          <Row className="">
            <Col>
              <LoadingOverlay
                active={loader}
                classNamePrefix="mitiz"
                spinner={<Spinner style={{ height: 60, width: 60}} />}
              >
              <div className="animated fadeIn content-wrapper rounded-2 bg-white mt-2 p-4">
                <Table responsive>
                  <thead>
                    <tr>
                      <th scope="col" className="border-top-0" width={7 + "%"}>
                        #
                      </th>
                      <th
                        scope="col"
                        className="border-top-0 sortable sort"
                        onClick={(event) => sortRecord(event, "name")}
                      >
                        Name
                        {sort.sort == "name" ? (
                          sort.direction == "asc" ? (
                            <BsChevronDown />
                          ) : (
                            <BsChevronUp />
                          )
                        ) : (
                          <BsChevronExpand />
                        )}
                      </th>
                      <th
                        scope="col"
                        className="border-top-0 sortable sort"
                        onClick={(event) => sortRecord(event, "email")}
                      >
                        Email
                        {sort.sort == "email" ? (
                          sort.direction == "asc" ? (
                            <BsChevronDown />
                          ) : (
                            <BsChevronUp />
                          )
                        ) : (
                          <BsChevronExpand />
                        )}
                      </th>
                      <th
                        scope="col"
                        className="border-top-0 sortable"
                        onClick={(event) => sortRecord(event, "is_admin")}
                      >
                        Is Admin
                        {sort.sort == "is_admin" ? (
                          sort.direction == "asc" ? (
                            <BsChevronDown />
                          ) : (
                            <BsChevronUp />
                          )
                        ) : (
                          <BsChevronExpand />
                        )}
                      </th>
                      <th  scope="col">Status</th>
                      <th
                        scope="col"
                        className="border-top-0 sortable sort"
                        onClick={(event) => sortRecord(event, "created_at")}
                      >
                        Added On
                        {sort.sort == "created_at" ? (
                          sort.direction == "asc" ? (
                            <BsChevronDown />
                          ) : (
                            <BsChevronUp />
                          )
                        ) : (
                          <BsChevronExpand />
                        )}
                      </th>
                      <th scope="col" className="border-top-0 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0
                      ? users.map((user, index) => (
                          <User
                            user={user}
                            key={`key-user-${index}`}
                            getUsers={getUsers}
                            openEditModal={openEditModal}
                            deleteUser={deleteUser}
                          />
                        ))
                      : !loader && (
                          <tr>
                            <td key={0} colSpan="7">
                              <p className="text-center botder-bottom-0">User not found.</p>
                            </td>
                          </tr>
                        )}
                  </tbody>
                  <tr>
                    <td colSpan="7">
                      {totalPages > perPageData && (
                        <ReactPaginate
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          pageCount={totalPages/perPageData}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination justify-content-center mb-0 pb-0"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      )}
                    </td>
                  </tr>
                </Table>
                </div>
              </LoadingOverlay>
            </Col>
          </Row>
        </Col>
      </Row>
      {open && (
        <AddEditUser
          open={open}
          handleClose={() => {
            setOpen();
            setUserId(null);
          }}
          userId={userId}
          users={users}
          getUsers={getUsers}
        />
      )}
    </div>
  );
};

export default Index;
