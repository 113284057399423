import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";

import LoadingOverlay from "react-loading-overlay";
import loan from "../../Services/loan";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddEditLoan extends Component {
  constructor(props) {
    super(props);
    this.searchTimeOut = 0;
    this.state = {
      fields: {
        city_field: null,
      },
      errors: {},
      loader: false,
      cities: [],
      submitted: false,
    };
  }

  validateForm = (props) => {
    let formIsValid = true;
    const errors = {};
    let fields = this.state.fields;
    if (!fields.city_field) {
      errors["city_field"] = "City  can not be empty.";
      formIsValid = false;
    }
    if (!fields.los_number) {
      errors["los_number"] = "Los number can not be empty.";
      formIsValid = false;
    }
    if (!fields.customer_name) {
      errors["customer_name"] = "Customer name can not be empty.";
      formIsValid = false;
    }
    if (!fields.customer_phone) {
      errors["customer_phone"] = "Customer phone can not be empty.";
      formIsValid = false;
    }
    if (!fields.customer_address) {
      errors["customer_address"] = "Customer address can not be empty.";
      formIsValid = false;
    }
    if (!fields.bank_name) {
      errors["bank_name"] = "Bank name can not be empty.";
      formIsValid = false;
    }
    if (!fields.loan_type) {
      errors["loan_type"] = "Loan Type can not be empty.";
      formIsValid = false;
    }
    if (!fields.amount) {
      errors["amount"] = "Amount can not be empty.";
      formIsValid = false;
    }
    if (!fields.remark) {
      errors["remark"] = "Remark can not be empty.";
      formIsValid = false;
    }
    this.setState({ errors });
    return formIsValid;
  };

  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ submitted: true });
      let fields = this.state.fields;
      fields["city_id"] = fields.city_field.value;
      loan.addUpdateLoan(fields).then((res) => {
        if (res.data.success) {
          this.setState({ submitted: false }, () => {
            this.props.closeModal();
          });
          this.props.getLoans();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          let errors = {};
          for (let key in res.data.errors) {
            errors[key] = res.data.errors[key];
          }
          this.setState({ errors, submitted: false });
        }
      });
    }
  };

  getSingleLoan = (id) => {
    this.setState({ loader: true });
    loan
      .getOneLoan(id)
      .then((res) => {
        if (res.data.success) {
          let fields = res.data.loan;
          fields["city_field"] = {
            label: fields.city.name,
            value: fields.city.id,
          };
          fields["created_at"] = new Date(fields.created_at);
          this.setState({ fields });
          this.setState({ loader: false });
        }
      })
      .catch(function (error) {
        this.setState({ loader: false });
      });
  };

  componentDidMount() {
    if (this.props.loanId) {
      this.getSingleLoan(this.props.loanId);
    }
    loan.getCities().then((response) => {
      if (response.data.success) {
        let cities = [];
        response.data.areas.forEach((city, index) => {
          cities[index] = {
            label: city.name,
            value: city.id,
          };
        });
        this.setState({
          cities,
          dropdownPlaceholder:
            cities.length > 0 ? "Select" : "Record not found",
        });
      }
    });
  }

  promiseCities = (inputValue) => {
    if (this.searchTimeOut > 0) {
      clearTimeout(this.searchTimeOut);
    }
    return new Promise((resolve) => {
      if (inputValue !== "") {
        this.searchTimeOut = setTimeout(() => {
          loan.getCities({ keyword: inputValue }).then((response) => {
            if (response.data.success) {
              let cities = [];
              response.data.areas.forEach((city, index) => {
                cities[index] = {
                  label: city.name,
                  value: city.id,
                };
              });
              this.setState(
                {
                  cities,
                  dropdownPlaceholder:
                    cities.length > 0 ? "Select" : "Record not found",
                },
                () => {
                  resolve(this.filterCity(inputValue));
                }
              );
            }
          });
        }, 500);
      } else {
        resolve(this.filterCity(inputValue));
      }
    });
  };

  filterCity = (inputValue) => {
    return this.state.cities.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  handleCityChange = (val) => {
    let fields = this.state.fields;
    fields["city_field"] = val;
    this.setState({ fields });
  };

  handleDate = (date) => {
    let fields = this.state.fields;
    fields["created_at"] = date;
    this.setState({ fields });
  };
  render() {
    return (
      <Modal isOpen={this.props.showAddEditModal} size="lg">
        <ModalHeader toggle={this.props.closeModal}>
          {this.props.loanId ? "Update " : "Add New"} Loan
        </ModalHeader>
        <LoadingOverlay
          active={this.state.loader}
          spinner={<Spinner style={{ width: 60, height: 60 }} />}
          classNamePrefix="mitiz"
        >
          <ModalBody className="pl-4 pr-4">
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="sm_name">Sales Manager Name</Label>
                    <Input
                      id="sm_name"
                      name="sm_name"
                      value={
                        this.state.fields["sm_name"]
                          ? this.state.fields["sm_name"]
                          : ""
                      }
                      onChange={(event) => this.handleChange(event, "sm_name")}
                      invalid={this.state.errors["sm_name"] ? true : false}
                    />
                    <FormFeedback>{this.state.errors["sm_name"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="city_field">Area</Label>
                    <AsyncSelect
                      cacheOptions
                      name="city_field"
                      value={this.state.fields.city_field}
                      defaultOptions={
                        this.state.cities ? this.state.cities : []
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      loadOptions={this.promiseCities}
                      onChange={this.handleCityChange}
                      isClearable={true}
                      placeholder="Search City"
                    />
                    {this.state.errors.city_field && (
                      <span className="text-danger">
                        {this.state.errors.city_field}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="los_number">LOS Number</Label>
                    <Input
                      id="los_number"
                      name="los_number"
                      value={
                        this.state.fields["los_number"]
                          ? this.state.fields["los_number"]
                          : ""
                      }
                      onChange={(event) =>
                        this.handleChange(event, "los_number")
                      }
                      invalid={this.state.errors["los_number"] ? true : false}
                    />
                    <FormFeedback>
                      {this.state.errors["los_number"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="customer_name">Full Name</Label>
                    <Input
                      id="customer_name"
                      name="customer_name"
                      type="text"
                      value={
                        this.state.fields["customer_name"]
                          ? this.state.fields["customer_name"]
                          : ""
                      }
                      onChange={(event) =>
                        this.handleChange(event, "customer_name")
                      }
                      invalid={
                        this.state.errors["customer_name"] ? true : false
                      }
                    />
                    <FormFeedback>
                      {this.state.errors["customer_name"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="customer_phone">Phone Number</Label>
                    <Input
                      id="customer_phone"
                      name="customer_phone"
                      type="text"
                      value={
                        this.state.fields["customer_phone"]
                          ? this.state.fields["customer_phone"]
                          : ""
                      }
                      onChange={(event) =>
                        this.handleChange(event, "customer_phone")
                      }
                      invalid={
                        this.state.errors["customer_phone"] ? true : false
                      }
                    />
                    <FormFeedback>
                      {this.state.errors["customer_phone"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Label for="customer_address">Address</Label>
                    <Input
                      id="customer_address"
                      name="customer_address"
                      type="text"
                      value={
                        this.state.fields["customer_address"]
                          ? this.state.fields["customer_address"]
                          : ""
                      }
                      onChange={(event) =>
                        this.handleChange(event, "customer_address")
                      }
                      invalid={
                        this.state.errors["customer_address"] ? true : false
                      }
                    />
                    <FormFeedback>
                      {this.state.errors["customer_address"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="bank_name">Company</Label>
                    <Input
                      id="company"
                      name="company"
                      type="text"
                      value={
                        this.state.fields["company"]
                          ? this.state.fields["company"]
                          : ""
                      }
                      onChange={(event) => this.handleChange(event, "company")}
                      invalid={this.state.errors["company"] ? true : false}
                      error={this.state.errors["company"] ? true : false}
                    />
                    <FormFeedback>{this.state.errors["company"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="bank_name">Bank Name</Label>
                    <Input
                      id="bank_name"
                      name="bank_name"
                      type="text"
                      value={
                        this.state.fields["bank_name"]
                          ? this.state.fields["bank_name"]
                          : ""
                      }
                      onChange={(event) =>
                        this.handleChange(event, "bank_name")
                      }
                      invalid={this.state.errors["bank_name"] ? true : false}
                      error={this.state.errors["bank_name"] ? true : false}
                    />
                    <FormFeedback>
                      {this.state.errors["bank_name"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="loan_type">Select Loan Type</Label>
                    <Input
                      type="select"
                      name="loan_type"
                      value={
                        this.state.fields["loan_type"]
                          ? this.state.fields["loan_type"]
                          : ""
                      }
                      onChange={(event) =>
                        this.handleChange(event, "loan_type")
                      }
                      invalid={this.state.errors["loan_type"] ? true : false}
                      id="exampleSelect"
                    >
                      <option value="">Select</option>
                      <option vlaue="BL">BL</option>
                      <option vlaue="BL/ENH">BL/ENH</option>
                      <option vlaue="BL/OD">BL/OD</option>
                      <option vlaue="HL">HL</option>
                      <option value="PL/BT">PL/BT</option>
                      <option vlaue="LAP">LAP</option>
                      <option vlaue="OD">OD</option>
                      <option value="PL">PL</option>
                      <option value="PL/BT/ENH">PL/BT/ENH</option>
                      <option vlaue="PL/ENH">PL/ENH</option>
                      <option vlaue="PL/GOLDENEDGE">PL/GOLDENEDGE</option>
                      <option vlaue="SEP">SEP</option>
                      <option vlaue="SEP/ENH">SEP/ENH</option>
                    </Input>
                    <FormFeedback>
                      {this.state.errors["loan_type"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="customer_phone">Amount</Label>
                    <Input
                      id="amount"
                      name="amount"
                      min={0}
                      type="number"
                      value={
                        this.state.fields["amount"]
                          ? this.state.fields["amount"]
                          : ""
                      }
                      onChange={(event) => this.handleChange(event, "amount")}
                      invalid={this.state.errors["amount"] ? true : false}
                    />
                    <FormFeedback>{this.state.errors["amount"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="customer_phone">Amount Disbursed</Label>
                    <Input
                      id="amount_disbursed"
                      name="amount_disbursed"
                      min={0}
                      type="number"
                      value={
                        this.state.fields["amount_disbursed"]
                          ? this.state.fields["amount_disbursed"]
                          : ""
                      }
                      onChange={(event) =>
                        this.handleChange(event, "amount_disbursed")
                      }
                      invalid={
                        this.state.errors["amount_disbursed"] ? true : false
                      }
                    />
                    <FormFeedback>
                      {this.state.errors["amount_disbursed"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={this.state.fields.id ? 6 : 12}>
                  <FormGroup>
                    <Label for="remark">Remark</Label>
                    <Input
                      type="select"
                      name="remark"
                      value={
                        this.state.fields["remark"]
                          ? this.state.fields["remark"]
                          : ""
                      }
                      onChange={(event) => this.handleChange(event, "remark")}
                      invalid={this.state.errors["remark"] ? true : false}
                      id="remarkSelect"
                    >
                      <option value="">Select</option>
                      <option vlaue="Login">Login</option>
                      <option vlaue="Approved">Approved</option>
                      <option vlaue="Disbursed">Disbursed</option>
                      <option vlaue="Rejected">Rejected</option>
                    </Input>
                    <FormFeedback>{this.state.errors["remark"]}</FormFeedback>
                  </FormGroup>
                </Col>
                {this.state.fields.id && (
                  <Col md={6}>
                    <FormGroup>
                      <Label for="created_at">Added On</Label>
                      <DatePicker
                        selected={this.state.fields.created_at}
                        onChange={this.handleDate}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                 )}
                <Col md={12}>
                  <FormGroup>
                    <Label for="remark">Additional Remark</Label>
                    <Input
                      name="additional_remark"
                      type="textarea"
                      value={
                        this.state.fields["additional_remark"]
                          ? this.state.fields["additional_remark"]
                          : ""
                      }
                      onChange={(event) =>
                        this.handleChange(event, "additional_remark")
                      }
                      invalid={
                        this.state.errors["additional_remark"] ? true : false
                      }
                    />

                    <FormFeedback>
                      {this.state.errors["additional_remark"]}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <ModalFooter className="pb-0">
                  <Button
                    type="button"
                    size="md"
                    outline
                    className="me-1"
                    color="secondary"
                    onClick={this.props.closeModal}
                    disabled={this.state.submitted}
                  >
                    Close
                  </Button>
                  <Button
                    color="danger"
                    size="md"
                    type="submit"
                    disabled={this.state.submitted}
                  >
                    {this.state.submitted && (
                      <Spinner size="sm" className="mx-1" />
                    )}
                    {this.props.loanId ? "Update" : "Save"}
                  </Button>
                </ModalFooter>
              </Row>
            </Form>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    );
  }
}

export default AddEditLoan;
