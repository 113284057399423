import React, { Component } from "react";
import moment from "moment";
import {
  Collapse,
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import AsyncSelect from "react-select/async";
import loan from "../../Services/loan";
import { BsFillArrowDownCircleFill } from "react-icons/bs";

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      disabled: false,
      isloader: false,
      area: "",
      dateFormat: "MM-DD-YYYY",
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        "Last Three Months": [moment().subtract(3, "month"), moment()],
        "Last Six Months": [moment().subtract(6, "month"), moment()],
      },
      autoUpdateInput: false,
      cities: [],
    };
  }
  handleDateRange = (startDate, endDate) => {
    let fields = this.state.fields;
    fields["date_range"] =
      startDate.format("MM-DD-YYYY") + " to " + endDate.format("MM-DD-YYYY");
    this.setState({ fields });
    this?.props?.searchParamsfields(fields);  
  };

  clearDateRange = () => {
    let fields = this.state.fields;
    fields["date_range"] = "";
    this.setState({ fields });
  };

  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    this?.props?.searchParamsfields(fields);
  };

  handleSearch = (e) => {
    e.preventDefault();
    // this.props.searchRecord(this.state.fields);
    if (this.props.getFieldsfromSearch) {
      this.props.getFieldsfromSearch(this.state.fields);
    }
  };

  exportRecord = () => {
    this.setState({
      isloader: true,
      disabled: true,
    });
    let params = {
      ...this.state.fields,
    };
    loan.getExcel(params).then((res) => {
      this.setState({
        isloader: false,
      });
      if (res.data) {
        try {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `loans.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            disabled: false,
          });
        } catch (error) {
          this.setState({
            isloader: false,
            disabled: false,
          });
          console.log(error);
        }
      }
    });
  };

  
  clearSearch = () => {
    
    this.setState({ fields: {}, area: "" }, () => {
      this.props.resetPage();
      this.props.getFieldsfromSearch({});
    });
    this?.props?.searchParamsfields({}); 
  };

  promiseCities = (inputValue) => {
    if (this.searchTimeOut > 0) {
      clearTimeout(this.searchTimeOut);
    }
    return new Promise((resolve) => {
      if (inputValue !== "") {
        this.searchTimeOut = setTimeout(() => {
          loan.getCities({ keyword: inputValue }).then((response) => {
            if (response.data.success) {
              let cities = [];
              response.data.areas.forEach((city, index) => {
                cities[index] = {
                  label: city.name,
                  value: city.id,
                };
              });
              this.setState(
                {
                  cities,
                  dropdownPlaceholder:
                    cities.length > 0 ? "Select" : "Record not found",
                },
                () => {
                  resolve(this.filterCity(inputValue));
                }
              );
            }
          });
        }, 500);
      } else {
        resolve(this.filterCity(inputValue));
      }
    });
  };

  filterCity = (inputValue) => {
    return this.state.cities.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  handleCityChange = (val) => {
    let fields = this.state.fields;
    fields["city_field"] = val;
    fields["city_id"] = val.value;
    this.setState({ fields });
    this?.props?.searchParamsfields(fields);
  };

  render() {
    return (
      <Collapse isOpen={this.props.isOpen}>
        <Card className="mt-2">
        
          <CardBody className="pt-0">
          <div className="text-dark py-3 ">
            {this.props.heading}
          </div>
            <Form
              name="add-edit-action-form"
              method="post"
              onSubmit={this.handleSearch}
            >
              <Row>
                {this.props.searchFields.length > 0 &&
                  this.props.searchFields.map((ele, index) => (
                    <Col
                      md={ele.grid !== undefined ? ele.grid : 4}
                      key={`search-field-key-${index}`}
                    >
                      <FormGroup className="mb-2 mr-sm-2 mb-3">
                        <Label className="mr-sm-2 label_text">{ele.label}</Label>
                        {ele.type === "text" && (
                          <Input
                            type={ele.type}
                            name={ele.name}
                            id={ele.name}
                            value={
                              this.state.fields[ele.name]
                                ? this.state.fields[ele.name]
                                : ""
                            }
                            onChange={(event) =>
                              this.handleChange(ele.name, event)
                            }
                            className="input-bg"
                            autoComplete="none"
                          />
                        )}
                        {ele.type == "remark" && (
                          <FormGroup>
                            <Input
                              type="select"
                              name="remark"
                              value={
                                this.state.fields["remark"]
                                  ? this.state.fields["remark"]
                                  : ""
                              }
                              onChange={(event) =>
                                this.handleChange(ele.name, event)
                              }
                              invalid={
                                this.state.errors["remark"] ? true : false
                              }
                              id="remarkSelect"
                            >
                              <option value="">Select</option>
                              <option vlaue="Login">Login</option>
                              <option vlaue="Approved">Approved</option>
                              <option vlaue="Disbursed">Disbursed</option>
                              <option vlaue="Rejected">Rejected</option>
                            </Input>
                            {/* <FormFeedback>{this.state.errors["remark"]}</FormFeedback> */}
                          </FormGroup>
                        )}
                        {ele.type === "select-async" && (
                          <AsyncSelect
                            cacheOptions
                            name={ele.name}
                            value={
                              this.state.fields[ele.name]
                                ? this.state.fields[ele.name]
                                : {}
                            }
                            defaultOptions={
                              this.state.cities ? this.state.cities : []
                            }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            loadOptions={this.promiseCities}
                            onChange={this.handleCityChange}
                            isClearable={true}
                            placeholder="Search City"
                          />
                        )}

                        {ele.type === "date-range" && (
                          <DateRangePicker
                            initialSettings={{
                              locale: {
                                format: this.state.dateFormat,
                                cancelLabel: "Clear",
                              },
                              ranges: this.state.ranges,
                              autoUpdateInput: false,
                              startDate: moment().format("MM-DD-YYYY"),
                              endDate: moment().format("MM-DD-YYYY"),
                            }}
                            onCallback={this.handleDateRange}
                            onCancel={this.clearDateRange}
                          >
                            <input
                              label={ele.label}
                              name={ele.name}
                              value={
                                this.state.fields["date_range"]
                                  ? this.state.fields["date_range"]
                                  : ""
                              }
                              type="text"
                              placeholder="Choose Date"
                              className="form-control"
                              autoComplete="false"
                            />
                          </DateRangePicker>
                        )}
                      </FormGroup>
                    </Col>
                  ))}
              </Row>
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  {this.props.exportExcel && (
                    <Button
                      color="success"
                      disabled={this.state.disabled}
                      onClick={this.exportRecord}
                      className="me-2"
                    >
                      {this.state.isloader ? (
                        <Spinner
                          style={{
                            width: "1rem",
                            height: "1rem",
                          }}
                        />
                      ) : (
                        <BsFillArrowDownCircleFill className="me-1" />
                      )}
                      Export
                    </Button>
                  )}
                  <button
                    type="button"
                    className="btn btn-outline-dark me-2"
                    onClick={this.clearSearch}
                  >
                    Clear
                  </button>
                  <button type="submit" className="btn btn-danger  text-white">
                    Search
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Collapse>
    );
  }
}

export default Search;
