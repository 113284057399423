import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Col, Row, Table, Spinner, Button } from "reactstrap";
import Search from "../Search";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import loan from "../../Services/loan";
import { BsChevronDown, BsChevronExpand, BsChevronUp } from "react-icons/bs";
import AddEditLoan from "./AddEditLoan";
import Loan from "./Loan";

const Index = (props) => {
 
  const [loans, setLoans] = useState([]);
  const [loader, setLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [sort, setSort] = useState({});
  const [loanId, setLoanId] = useState(null);
  const [searchParams, setSearchParams] = useState({})
  const [perPageData, setPerPageData] = useState(0)
  const [searchFields, setSearchFields] = useState([
    { label: "Name", name: "customer_name", type: "text", col: 4 },
    { label: "Company Name", name: "company", type: "text", col: 4 },
    { label: "Phone", name: "customer_phone", type: "text", col: 4 },
    { label: "Area", name: "city_field", type: "select-async", values: [], col: 4},
    { label: "Date Range", name: "date_range", type: "date-range", col: 4 },
    { label: "Remark", name: "remark", type: "remark", values:[] },
  ]);

  const openEditModal = (loan_id) => {
    setLoanId(loan_id);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const TriggerToggle = () => {
    setToggle(!toggle);
  };

  const getLoans = (fields = {}) => {
    let params;
    if(searchParams){
      params = { ...fields, ...searchParams, ...sort, page: page }
    }else{
      setSearchParams({})
      params = { ...fields, ...sort, page: page }
    }
    setLoader(true);
    
    loan
      .loans(params)
      .then((res) => {
        if (res.data.success) {
          if(!searchParams){
            setPage(res.data.loans.current_page);
          }
          setTotalPages(Math.floor(res.data.loans.total));
          setPerPageData(res.data.loans.per_page)
          setLoans(res.data.loans.data);
          setLoader(false);
          setPageLimit(res.data.loans.per_page);
          if (res.data.loans.data.length > 0) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }; 
  
  const deleteLoan = (id) => {
    if (window.confirm("Are you sure to delete this loan?")) {
      loan.loanDelete(id).then((res) => {
        if (res.data.success) {
          const newLoan = loans.filter((item) => item.id !== id);
          setLoans(newLoan);
          handleCloseUserMenu();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handlePageChange = (page) => {
    setLoader(true);
    setPage(page.selected + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const sortRecord = (e, column) => {
    e.persist();
    let sort, direction;
    if (e.target.className.indexOf("sortable") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    } else if (e.target.className.indexOf("asc") > 0) {
      sort = column;
      direction = "desc";
      e.target.className = "border-top-0 desc";
    } else if (e.target.className.indexOf("desc") > 0) {
      sort = column;
      direction = "asc";
      e.target.className = "border-top-0 asc";
    }
    setSort({
      sort,
      direction,
    });
  };

  const getSearchFields = (fields = {}) => {
    // setExportFields(fields);
    if (searchParams) {
      setPage(1);
      getLoans(fields);
    }
    setTotalPages(parseInt(totalPages / perPageData));
  };

  useEffect(() => { 
      getLoans(sort, page);
  }, [sort, page]);

  useEffect(() => {
    if(loanId !== null){
      setShowAddEditModal(true)
    }
  }, [loanId]);

  const resetPage = () => {
    setPage(1);
  }

  return (
    <div>
      <Helmet>
        <title>Loans</title>
      </Helmet>
      <Row>
        <Col xl={12}>
          <Row>
            <Col md={8} sm={6}>
              <h3 className="mt-2 text_title">Loans</h3>
            </Col>
            <Col md={4} sm={12} className="text-end">
              <Button
                color="warning"
                type="button"
                onClick={TriggerToggle}
                className="mx-2"
              >
                Search
              </Button>
              <Button
                color="success"
                type="button"
                onClick={() => setShowAddEditModal(true)}
              >
                Add New Loan
              </Button>
            </Col>
          </Row>
          
          <Search
            isOpen={toggle}
            heading="Search Loan"
            searchFields={searchFields}
            exportExcel={true}
            resetPage={resetPage}
            searchParamsfields={setSearchParams}
            getFieldsfromSearch={getSearchFields}
          />

          <Row className="mt-2">
            <Col>
              <LoadingOverlay
                active={loader}
                spinner={<Spinner style={{ height: 60, width: 60}} />}
                classNamePrefix="mitiz"
              >
                <div className="table-responsive animated fadeIn content-wrapper rounded-2 bg-white mt-2 p-4">
                <Table responsive className="">
                  <thead>
                    <tr>
                      <th scope="col" className="border-top-0">Sr No.</th>
                      <th
                        scope="col"
                        className="border-top-0 sortable sort"
                        onClick={(event) => sortRecord(event, "customer_name")}
                      >
                        Name
                        {sort.sort == "customer_name" ? (
                          sort.direction == "asc" ? (
                            <BsChevronDown />
                          ) : (
                            <BsChevronUp />
                          )
                        ) : (
                          <BsChevronExpand />
                        )}
                      </th>
                      <th scope="col">Manager</th>
                      <th scope="col">City</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Bank</th>
                      <th
                        scope="col"
                        className="border-top-0 sortable sort"
                        onClick={(event) => sortRecord(event, "amount")}
                      >
                        Amount
                        {sort.sort == "amount" ? (
                          sort.direction == "asc" ? (
                            <BsChevronDown />
                          ) : (
                            <BsChevronUp />
                          )
                        ) : (
                          <BsChevronExpand />
                        )}
                      </th>
                      <th
                        scope="col"
                        className="border-top-0 sortable sort"
                        onClick={(event) => sortRecord(event, "created_at")}
                      >
                        Added On
                        {sort.sort == "created_at" ? (
                          sort.direction == "asc" ? (
                            <BsChevronDown />
                          ) : (
                            <BsChevronUp />
                          )
                        ) : (
                          <BsChevronExpand />
                        )}
                      </th>
                      <th scope="col" className="border-top-0 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loans.length > 0
                      ? loans.map((loan, index) => (
                          <Loan
                            loan={loan}
                            key={`key-loan-${index}`}
                            index={index}
                            deleteLoan={deleteLoan}
                            openEditModal={openEditModal}
                            page={page}
                            pageLimit={pageLimit}
                          />
                        ))
                      : !loader && (
                          <tr>
                            <td colSpan="9" className="text-center botder-bottom-0">
                              Record not found.
                            </td>
                          </tr>
                        )}
                  </tbody>
                  <tr>
                    <td colSpan="9">
                      {totalPages > perPageData && (
                        <ReactPaginate
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          pageCount={totalPages/perPageData}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination justify-content-center mb-0 pb-0"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      )}
                    </td>
                  </tr>
                </Table>
                </div>
              </LoadingOverlay>
            </Col>
          </Row>
        </Col>
      </Row>
      {showAddEditModal && (
        <AddEditLoan
          showAddEditModal={showAddEditModal}
          closeModal={() => {
            setShowAddEditModal();
            setLoanId(null);
          }}
          loanId={loanId}
          getLoans={getLoans}
        />
      )}
    </div>
  );
};

export default Index;
