import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import user from "../../Services/user";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Index = (props) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      let params = fields;
      setLoader(true);
      user
        .forgetpassword(params)
        .then((res) => {
          setLoader(false);
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            setLoader(false);
          } else {
            let errors = {};
            for (let key in res.data.errors) {
              errors[key] = res.data.errors[key];
            }
            setErrors(errors);
            setLoader(false);
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setLoader(false);
        });
  };

  return (
    <>
     <Helmet>
          <title>Forgot Password : credit-flow</title>
        </Helmet> 
      <section>
        <Container className="px-5">
          <Row className="justify-content-center">
            <Col md={6} className="mb-4">
              <h1>Build your next project faster with SB UI Kit Pro</h1>
              <p>
                Welcome to SB UI Kit Pro, a toolkit for building beautiful web
                interfaces, created by the development team at Start Bootstrap
              </p>
            </Col>

            <Col md={6}>
              <Card className="login">
                <CardHeader>
                  <h6 className="text-primary mt-2">Forgot Password</h6>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            type="email"
                            value={fields["email"] ? fields["email"] : ""}
                            onChange={(event) => handleChange(event, "email")}
                            invalid={errors["email"] ? true : false}
                          />
                          <FormFeedback>{errors["email"]}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={8} className="d-grid gap-2 mx-auto text-center">
                      <Button
                          color="success"
                          size="lg"
                          type="submit"
                          disabled={loader}
                        >
                          {loader ? (
                            <Spinner
                              style={{
                                width: "1rem",
                                height: "1rem",
                                // marginLeft: "5px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          Submit
                        </Button>
                        <Link to="/login">Login</Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="svg-border-rounded text-white banner-arc">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="currentColor"
        >
          <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
        </svg>
      </div>
    </>
  );
};

export default Index;
