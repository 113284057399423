import React from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';
import { BsPlusLg, BsFillTrash2Fill, BsStarFill, BsFileEarmarkTextFill, BsFillTrashFill, BsLink45Deg } from "react-icons/bs";
import { FaRegHandshake } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MyCard = () => {

    const [activeTab, setActiveTab] = React.useState('newTicket');

    return (
        <div>
            <Row>
                <Col sm={3}>
                    <Card className="shadow support_aside mb-5">
                        <Nav tabs fill>
                            <NavItem>
                                <NavLink active={activeTab == 'newTicket'} onClick={() => setActiveTab('newTicket')}>
                                    <BsPlusLg /> Make New Ticket
                        </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab == 'openTicket'} onClick={() => setActiveTab('openTicket')}>
                                    <BsFileEarmarkTextFill /> View Open Tickets
                        </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab == 'closeTicket'} onClick={() => setActiveTab('closeTicket')}>
                                    <BsFillTrash2Fill /> View Closed Tickets
                        </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab == 'starredTicket'} onClick={() => setActiveTab('starredTicket')}>
                                    <BsStarFill /> View Starred Tickets
                        </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab == 'manage'} onClick={() => setActiveTab('manage')}>
                                    <FaRegHandshake /> Managed Services
                        </NavLink>
                            </NavItem>
                        </Nav>
                    </Card>
                </Col>
                <Col sm={9}>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="newTicket">
                            <NewTicket />
                        </TabPane>
                        <TabPane tabId="openTicket">
                            <OpenTicket />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </div>
    );
};

const NewTicket = (props) => {
    return (
        <div className="support_article">
            <h5>Case #290225 <span className="badge bg-warning-soft text-warning fw-normal text-xs" style={{ float: 'right' }}>Awaiting Response</span></h5>
            <hr />
            <Card className="shadow support_aside">
                <CardHeader>
                    <Row>
                        <Col>
                            <h6 className="mb-0">
                                Richard S.
                                <br />
                                <p className="text-xs text-muted">November 19, 2020 at 11:31 PM</p>
                            </h6>
                        </Col>
                        <Col>
                            <h5 className="text-primary" style={{ float: 'right' }}>#290225</h5>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p>Hello,</p>
                    <p>We hope you are well.</p>
                    <p>We have found an email account on your server to be sending out spam, we have changed the email password to the following:</p>
                    <pre>we823#d*LKe2dD29!d</pre>
                    <p>We are removing the malicious spam messages from the mail queue. Please be aware, mail service may be temporarily disabled in order for us to finish this process. We apologize for any inconvenience this may cause.</p>
                    <p className="mb-0">If you have any questions don't hesitate to contact us.</p>
                    <hr />
                    <p className="mb-0 small">
                        Richard S.
                    <br />
                        Abuse Response Analyst
                    <br />
                        <Link to="#!">https://www.example.com/support</Link>
                    </p>
                </CardBody>
            </Card>

            <Card className="shadow support_aside mt-5">
                <CardHeader>
                    <Row>
                        <Col>
                            <h5 className="text-primary">Add Reply</h5>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Input
                        id="exampleText"
                        name="text"
                        type="textarea"
                        />
                    </FormGroup>
                    <Button color="primary" size="sm" className="me-2">Add Reply</Button>
                    <Button outline size="sm" className="me-2"><BsLink45Deg /> Add a File</Button>
                    <Button outline size="sm"><BsFillTrashFill /> Mark as Resolved</Button>
                </CardBody>
            </Card>
        </div>
    )
}

const OpenTicket = (props) => {
    return (
        <>
            <h3> View Open Tickets</h3>
        </>
    )
}

function SupportArea() {
    return (
        <Container className="px-5">
            <div className="support_layout">
                <Row className="justify-content-center">
                    <Col sm="12" className="text-center mt-5 mb-5">
                        <MyCard />
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default SupportArea;