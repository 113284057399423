import http from "./http";

const user = {
  login: data => http.post("/auth/login", data),
  logout: data => http.post("/logout", data),
  user: param => http.get("/user/list", { params: param }),
  userDelete: (param) => http.delete(`/user/delete/${param}`),
  addUser: (data) => http.post('user/save', data),
  getOne: (param) => http.get(`/user/show/${param}`),
  updateEmail: data => http.post("/user/update-email", data),
  changepwd: data => http.post("/user/change-password", data),
  activeUser: param => http.get("/user/active", { params: param }),
  checkSubscription: param => http.get("/auth/check-subscription", { params: param }),
};

export default user;