import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import LoanDetailsBody from "../Loans/LoanDetailsBody";
import common from "../../Services/common";
import { MdDelete, MdModeEdit } from "react-icons/md";
const Loan = (props) => {
  const loan = props.loan;
  const [anchorEl, setAnchorEl] = useState(null);
  const [toggleId, setToggleId] = useState("");
  const [show, setShow] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleLoanModal = (loan_id) => {
    setShow(true);
    setToggleId(loan_id);
  };
  return (
    <>
      <tr key={loan.id}>
        <td>{(parseInt(props.pageLimit) * (parseInt(props.page) - 1) + (props.index + 1))}.</td>
        <td onClick={() => toggleLoanModal(loan.id)} style={{width:"150px"}}>
          <Link to={""} className="text-decoration-none">
            {loan.customer_name}
          </Link>
        </td>
        <td>{loan.sm_name}</td>
        <td>{loan.city.name}</td>
        <td title={loan?.additional_remark || loan.remark}><span style={{whiteSpace: 'nowrap',overflow:'hidden', textOverflow:'ellipsis', width: 100, display:'block'}}>{loan.remark}</span></td>
        <td>{loan.bank_name}</td>
        <td>{common.numberFormat(loan.amount)}</td>
        <td>{moment(loan.created_at).format("D MMM,  YYYY")}</td>
        <td className="text-center">
          <UncontrolledDropdown className="me-2" direction="bottom">
            <DropdownToggle caret color="primary" className="btn-sm">Action</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => props.openEditModal(loan.id)}>
                  <span className="me-2 icon_badge"><MdModeEdit  /></span> Edit</DropdownItem>
                <DropdownItem onClick={() => props.deleteLoan(loan.id)} className="text-danger">
                  <span className="me-2 icon_badge"><MdDelete /></span> Delete</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
      {show && (
        <Modal isOpen={show} toggleLoanModal={toggleLoanModal}>
          <ModalHeader> {loan.customer_name}'s Details</ModalHeader>
          <ModalBody className="pl-4 pr-4">
            <LoanDetailsBody loan={loan} />
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="md"
              outline
              className="me-1"
              color="secondary"
              onClick={() => {
                setShow();
              }}
            >
              Close
            </Button>
            <Button color="danger" size="md" type="submit"
              onClick=
              {() => {
                props.openEditModal(loan.id, props.scroll);
                setShow(false);
              }}
            >
             Edit
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default Loan;
