import React from "react";
import { Link } from "react-router-dom";
import {
  Container, Row, Col
} from 'reactstrap';
import { BsArrowLeftShort } from "react-icons/bs";

function Error(props) {

  return (
    <>
      <Container className="px-5">
        <Row>
          <Col>
            <div className="error text-center mt-5 mb-5 pt-3 pb-5">
                <img className="mt-5" src="../400-error.jpg" />
                <h6 className="text-muted">Your client has issued a malformed or illegal request.</h6>
                <Link to="/"><BsArrowLeftShort /> Return Home </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Error;
